import { getLocalData } from "../utils/helper";
const CURRENT_HOME = getLocalData("HOME");
export const ROUTES_URL = {
  HOME: CURRENT_HOME ? ("/sp/" + CURRENT_HOME).toString() : "/",
  LOGIN: "/login",
  REGISTER: "/register",
};


//----------------------backend-api url-------------------//
// const API_GATEWAY = "http://localhost:8080";
// const API_GATEWAY = "https://cc-api-phase2.herokuapp.com";
const API_GATEWAY = "https://theclovercarte.com";

//----------------------Websocket url-------------------//
// export const WEBSOCKET_GATEWAY = "ws://localhost:8080/WebSocket";
// export const WEBSOCKET_GATEWAY = "wss://cc-api-phase2.herokuapp.com/Websocket";
export const WEBSOCKET_GATEWAY = "wss://theclovercarte.com";

export const API_URL = {
  ORDER: `${API_GATEWAY}/order`,
  GET_ORDERS: `${API_GATEWAY}/get-order`,
  GET_PREVIOUS_ORDER: `${API_GATEWAY}/order/previous`,
  GET_ORDER_BY_ID: `${API_GATEWAY}/order/get`,
  ORDER_COMPLETE: `${API_GATEWAY}/is-order-complete`,
  THANKYOU: `${API_GATEWAY}/thank-you`,

  GET_CATEGORY: `${API_GATEWAY}/category/get`,
  GET_CATEGORY_BY_POINT: `${API_GATEWAY}/category/sp`,
  GET_PRODUCT: `${API_GATEWAY}/products/get`,
  GET_PRODUCT_BY_POINT: `${API_GATEWAY}/products/point`,

  GET_SALES_POINT_BY_ID: `${API_GATEWAY}/sales-point/get`,

  GET_GUEST_SESSION: `${API_GATEWAY}/user/guest-session`,

  POST_REVIEW: `${API_GATEWAY}/review/create`,
  UPDATE_REVIEW: `${API_GATEWAY}/review/update`,

  USER_REGISTER: `${API_GATEWAY}/user/register`,
  USER_GOOGLE_SIGNUP: `${API_GATEWAY}/user/register`,
  USER_LOGIN: `${API_GATEWAY}/user/login`,
  USER_GOOGLE_SIGN_IN: `${API_GATEWAY}/user/login`,
  SEND_OTP: `${API_GATEWAY}/user/send-otp`,
  VERIFY_OTP: `${API_GATEWAY}/user/verify-otp`,

  GET_WALLET: `${API_GATEWAY}/wallet/get`,
  GET_WALLET_TRANSACTIONS: `${API_GATEWAY}/wallet/transaction`,
  RECHARGE_WALLET: `${API_GATEWAY}/wallet/recharge`,
  ORDER_BY_WALLET: `${API_GATEWAY}/order-by-wallet`,
  WALLET_RECHARGE_COMPLETE: `${API_GATEWAY}/wallet/recharge-complete`,

  VERIFY_COUPON: `${API_GATEWAY}/coupon/verify`,

};


