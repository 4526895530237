import { useEffect, useState } from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { decodeMachineMsg } from "../../utils/decodeOrderstatus";
import { WEBSOCKET_GATEWAY } from "../../constants/url.constant";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { flushSync } from 'react-dom';
import VerifiedIcon from '@mui/icons-material/Verified';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CircularProgress from '@mui/material/CircularProgress';
import Rating from '@mui/material/Rating';
import { Stack } from "@mui/material";

import {
  OrderInfo,
  BlockTitle,
} from "../../features/order/order/order-received.style";
import { Divider } from "@mui/material";
import { DotLoader } from "../Loader/DotLoader/DotLoader";
import LinearWithPercentage from "../Loader/ProgressBar/LinearWithPercentage";
const client = new W3CWebSocket(WEBSOCKET_GATEWAY);
client.onopen = (connection) => {
  // connection opened
  // client.send('Hello from OrderStatus ');  // send a message
  // console.log("connected", connection)
};

export default function OrderStatus() {
  const [orderStatus, setOrderStatus] = useState([]);
  const [value, setValue] = useState(0);


  client.onmessage = (message) => {

    console.log("ping test", message.data)
    if (message.data != "ping") {
      updateOrderStatus(decodeMachineMsg(message.data))
    }
  }
  function updateOrderStatus(data) {
    let arr = orderStatus;
    let index = (arr.findIndex(x => x.pro_code === data.pro_code));
    if (index >= 0) {
      arr[index].message = data.message;
      arr[index].status = data.status;

      flushSync(() => {
        setOrderStatus(arr)
        //below state is used to re render component
        setOrderStatus(previousOrder => [...previousOrder])

      });

      // console.log("order state",orderStatus )
    }
    if (index === -1) {
      flushSync(() => {
        setOrderStatus(previousOrder => [...previousOrder, data])
      });
    }
  }

  useEffect(() => {

  }, []);

  function showBar(status) {
    if (status === "OK" || status === 'st' || status === 'pr') {
      return <LinearProgress />
    }
  }
  function showIcon(status) {
    if (status === 'FL' || status === 'ut' || status === 'to' || status === 'tt' || status === 'fl')
      return <ReportGmailerrorredIcon sx={{ color: 'red', fontSize: '20px' }} />

    if (status === 'kk') {
      return <VerifiedIcon sx={{ color: 'green', fontSize: '20px' }} />
    }
  }
  return (<div >
    {(orderStatus && orderStatus.length > 0) ?
      (
        orderStatus.map((order) => {
          return <Card spacing={2} sx={{ marginTop: '10px' }} >
            <Card sx={{}}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Order  : {order.pro_code} {showIcon(order.status)} <br></br>
                  <Box sx={{ width: '100%' }}>
                    {showBar(order.status)}
                  </Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {order.message}
                </Typography>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: 'space-around' }}>
                <Stack direction="row">
                  <Button size="small" disabled> Rating </Button>
                  <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                </Stack>

                <Divider orientation="vertical" flexItem />

                <Stack direction="row">
                  <Button size="small" >Help </Button>
                </Stack>

              </CardActions>
            </Card>
          </Card>
        })
      ) : (
        <OrderInfo>
          <BlockTitle>
            <Box sx={{ p: 2 }}>
              {/* <DotLoader /> */}
              <LinearWithPercentage />
            </Box>
          </BlockTitle>
        </OrderInfo>
      )
    }

  </div>
  );
}
