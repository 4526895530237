import { useState } from "react";
import {
  CardContent,
  FormControl,
  Grid,
  Typography,
  TextField,
  Rating
} from "@mui/material";
import { Box } from "@mui/system";
import StarIcon from "@mui/icons-material/Star";

import RatingComponent from "./Rating";
import { Paper } from "@material-ui/core";

function CommentAndRating(props) {

  const [hover, setHover] = useState(-1);
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState(null);
  const defaultReview = props.default;

  const handleChange = (e, newValue) => {
    setValue(newValue);
    props.onClick(newValue, "star")
  };

  const handleChangeComment = (e) => {
    e.preventDefault();
    setComment(e.target.value)
    props.onClick(e.target.value, "comment")
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        mb={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ borderBottom: "1px solid #F4F6F8", paddingBottom: "1rem" }}
      >
        <Grid item xs={12}>
          <Box m={1} mb={1} >
            <FormControl fullWidth component="fieldset">
              <TextField
                sx={{ width: "100%" }}
                id="outlined-multiline-static"
                label="Write us"
                multiline
                rows={4}
                defaultValue={comment || defaultReview?.comment}
                onChange={(e) => handleChangeComment(e)}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                minHeight: "100px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >

              <Rating
                sx={{}}
                name="hover-feedback"
                value={value || defaultReview?.star}
                precision={1}
                onChange={(event, newValue) => handleChange(event, newValue)}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
              />
              {value !== null && (
                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
              )}



            </Box>
          </CardContent>
        </Grid>
      </Grid>

    </>
  );
}

var labels = {
  1: "Useless",
  2: "Poor",
  3: "Ok",
  4: "Good",
  5: "Excellent",
};

export default CommentAndRating;
