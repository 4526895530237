import { useEffect, useState } from "react";
// library
import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  Fab,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Select,
  FormControl
} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import toastr from "toastr";
import PlaceIcon from '@mui/icons-material/Place';
import Page404 from '../pages/Page404/Page404.page'

import { setLocalData } from "../utils/helper.js";
// components
import Page from "../components/Page.js";
import { Outlet } from "react-router-dom";
// utils
import { getMethod } from "../utils/api";
// constants
import { API_URL } from "../constants/url.constant";
import { API_STATUS_CODE, MESSAGE } from "../constants/content.constant";
//context/store/state
import { useCart } from "../contexts/cart/use-cart";
import CategoryScrollbar from "../components/Categorgy/CategoryScrollbar.js";
import CheckoutBtnBottom from "../features/cart/CheckoutBtnBottom.jsx";

import { MHidden } from "../components/@material-extend";
import { makeStyles } from "@material-ui/core";
import { useParams } from 'react-router-dom'

const SORT_ITEMS_OPTIONS = [
  { value: "1", label: "Price High to Low" },
  { value: "2", label: "Price Low to High" },
  { value: "3", label: "Most Popular" },
  // { value: "4", label: "Reset All" },
]

// ----------------------------------------------------------------------

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "black"
    }
  },
  root2: {
    "&:hover": {
      backgroundColor: "green"
    }
  }
});

export default function DashboardApp() {
  const {
    addItem,
    removeItem,
    getItem,
    getItemQuantity,
    isInCart,
    cartItemsCount
  } = useCart();
  const { id } = useParams()
  const classes = useStyles();
  const [product, setProduct] = useState([]);
  const [maxItemLimit, setMaxItemLimit] = useState(false)
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [salesPoint, setSalesPoint] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [error, setError] = useState(false);
  const [varient, setVarient] = useState(0);
  const [variants, setVariant] = useState([]);
  //variant should be items based array



  useEffect(() => {
    try {
      fetchProductsByPoints();
      fetchSalesPointById();

    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClickFilterBtn = (event, item) => {
    const BY = item?.value
    filterProductBy(BY)
    setAnchorEl(event.currentTarget);
  };
  const filterProductBy = (filterBy) => {

    switch (filterBy) {
      case '1':
        filteredProduct.sort((a, b) => Number(b.price) - Number(a.price));
        break;
      case '2':
        filteredProduct.sort((a, b) => Number(a.price) - Number(b.price));
        break;
      case '4':
        setFilteredProduct(product)
        break;
      default:
        setFilteredProduct(product)
    }

  };
  useEffect(() => {


  }, [filteredProduct]);

  useEffect(() => {


  }, [error]);

  useEffect(() => {
    setLocalData("HOME", id.toUpperCase())

  }, []);

  const handleCloseFilterBtn = () => {
    setAnchorEl(null);
  };

  const sortProducts = (name) => {
    if (name === 'All') {
      setFilteredProduct(product)
    }
    if (name && name !== 'All') {
      let filterData = product?.filter(f => f.category === name)
      //  filteredProduct=  filterData
      setFilteredProduct(filterData)
    }
  }

  const MaxItemAlert = () => {
    toastr.success(MESSAGE.MAX_ITEM_LIMIT_ALERT);
    return false
  }

  const setSelectedVariant = (vari, index) => {
    const oldVariants = variants;
    const existingItemIndex = oldVariants.findIndex((c) => c.itemIndex == index);

    if (existingItemIndex > -1) {
      console.log("hello", existingItemIndex);

      let newState = oldVariants;
      newState[existingItemIndex].variantIndex = vari;
      setVariant(newState);
    } else {

      let oldArr = variants;

      let newVariant = { variantIndex: vari, itemIndex: index };

      oldArr.push(newVariant);
      console.log("else", oldArr)
      setVariant(oldArr);
    }
    //indexing of items in state
    setVarient(vari)
  }

  const getPriceIndex = (itemIndex) => {

    const existingItemIndex = variants?.findIndex(
      (variant) => variant.itemIndex === itemIndex
    );

    if (existingItemIndex > -1) {
      return variants[existingItemIndex].variantIndex
    } else {
      return 0;
    }

  }

  const sendSelectedItemToCart = (item, index) => {

    item.price = item?.recipes[getPriceIndex(index)].price;
    item.recipe = item?.recipes[getPriceIndex(index)].code;
    addItem(item);
  }

  const removeSelectedItemFromCart = (item, index) => {
    item.price = item?.recipes[getPriceIndex(index)].price;
    item.recipe = item?.recipes[getPriceIndex(index)].code;
    removeItem(item);
  }

  const fetchGuestSession = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(API_URL.GET_GUEST_SESSION, false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {

      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  const fetchProductsByPoints = async () => {

    try {
      // setLoading(true);
      const response = await getMethod(API_URL.GET_PRODUCT_BY_POINT + "/" + id.toUpperCase(), false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setProduct(response?.data?.data);
        setFilteredProduct(response?.data?.data)
      }
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  const fetchSalesPointById = async () => {
    try {
      // setLoading(true);
      const response = await getMethod(API_URL.GET_SALES_POINT_BY_ID + "/" + id.toUpperCase(), false, true);
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setSalesPoint(response?.data?.data);
      }
      else {
        setError(true)
      }
      // setLoading(false);
    } catch (error) {
      console.log("error", error);
      // setLoading(false);

    }
  };

  const callback = (name) => {
    sortProducts(name)
  }
  useEffect(() => {
    try {
      fetchGuestSession();
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setMaxItemLimit((cartItemsCount() < 4) ? false : true)
  }, [cartItemsCount]);


  return (
    <>
      <Outlet />
      <Page title="Clover Carte | Home">
        {(!error) ?
          <Container>
            <Container maxWidth="xl" sx={{ flex: '1 0 auto', height: '100vh' }}>
              <Card sx={{ width: '80%', margin: 'auto', }}>
                <CategoryScrollbar spid={id.toUpperCase()} onClick={callback} />
              </Card>
              <Box>
                <Grid container spacing={2} sx={{ mt: 2 }} justifyContent="space-between">
                  <Grid item xs={10} >
                    <CardContent sx={{ p: 1 }}>

                      <Typography component="div" variant="subtitle2" color="text.secondary">
                        <PlaceIcon sx={{ size: '5px' }} />  {salesPoint?.city},
                      </Typography>
                      <Typography component="div" variant="h6">
                        Welcome at,  {salesPoint?.name}
                      </Typography>
                    </CardContent>
                  </Grid>

                  <Grid item xs={2} container justifyContent="flex-end">

                    <Tooltip title="Filter Items">
                      <IconButton
                        onClick={handleClickFilterBtn}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                      >
                        Filter  <FilterAltIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>

                </Grid>
              </Box>

              <Grid container spacing={1} sx={{ pt: 1 }}>
                {/* below is old product box  */}
                {
                  filteredProduct?.map((item, index) => {
                    return <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card sx={{ display: 'flex' }} >
                        <CardMedia
                          component="img"
                          sx={{ width: 115, height: 115, m: 1 }}
                          src={item?.images?.url}
                          alt={item?.title}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <CardContent sx={{ p: 1 }}>
                            <Typography component="div" variant="h6">
                              {item?.title}
                            </Typography>
                            <Typography component="div" variant="subtitle2" color="text.secondary">
                              {item?.description}
                            </Typography>

                            <Stack direction="row" spacing={6}>
                              <Typography variant="subtitle2" color="text.secondary" component="div">

                                Rs. {item?.recipes[getPriceIndex(index)]?.price}
                              </Typography>
                              <Typography variant="subtitle2" color="text.secondary" component="div">
                                <select
                                  value={variants[getPriceIndex(index)]?.name}
                                  onChange={(e) => setSelectedVariant(e.target.value, index)}
                                >
                                  {item.recipes?.map((recipe, i) => (
                                    <option key={recipe?.code} value={i}>{recipe?.name}</option>
                                  ))}
                                </select>

                              </Typography>
                            </Stack>
                          </CardContent>
                          <Box >
                            {isInCart && !isInCart(item._id) ?
                              <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                <Fab
                                  onClick={() => (cartItemsCount() === 3) ?
                                    MaxItemAlert() :
                                    sendSelectedItemToCart(item, index)} variant="extended" size="small" className={classes.root2} sx={{ backgroundColor: 'green', color: 'white' }} aria-label="add">
                                  Add to Cart
                                </Fab>
                              </Box> :
                              <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                <Fab variant="extended" size="small" className={classes.root} sx={{ backgroundColor: "black", color: 'white' }} aria-label="addDelete">

                                  <RemoveCircleOutlineIcon onClick={() => removeSelectedItemFromCart(item, index)} />
                                  <Typography sx={{ padding: '10px' }} variant="subtitle1" color="white" component="div">
                                    {getItemQuantity(item._id)}
                                  </Typography>

                                  <AddCircleOutlineIcon onClick={() => (cartItemsCount() === 3) ? MaxItemAlert() : (sendSelectedItemToCart(item, index))} />

                                </Fab>
                              </Box>
                            }
                          </Box>

                        </Box>
                      </Card>
                    </Grid>
                  })
                }

                {/*  below is new product box */}

                {/* {            
              filteredProduct?.map((item, index) => {
                return <Grid item xs={6} sm={4} md={3} key={index}>
                  <Card sx={{ maxWidth: 200, minWidth:180 }} >
                    <CardMedia
                      component="img"
                      height="120"
                      width="100"
                      src={item?.images?.url}
                      alt={item?.title}
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <CardContent sx={{ }}>
                        <Typography component="div" variant="h6">
                          {item?.title}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" component="div">
                          Rs. {item?.price}
                        </Typography>
                      </CardContent>

                      {isInCart && !isInCart(item._id) ?
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 2 , ml:1}}>
                          <Fab onClick={() => (cartItemsCount() === 3) ? MaxItemAlert() : (addItem(item))} variant="extended" size="small" className={classes.root2} sx={{ backgroundColor: 'green', color: 'white' }} aria-label="add">
                            Add to Cart
                          </Fab>
                        </Box> :
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 2 ,ml:1}}>
                          <Fab variant="extended" size="small" className={classes.root} sx={{ backgroundColor: "black", color: 'white' }} aria-label="addDelete">

                            <RemoveCircleOutlineIcon onClick={() => removeItem(item)} />
                            <Typography sx={{ padding: '10px' }} variant="subtitle1" color="white" component="div">
                              {getItem(item._id)?.quantity}
                            </Typography>

                            <AddCircleOutlineIcon onClick={() => (cartItemsCount() === 3) ? MaxItemAlert() : (addItem(item))} />

                          </Fab>
                        </Box>
                      }
                    </Box>
                  </Card>
                </Grid>
              })
            } */}

              </Grid>
            </Container>
            <MHidden width="smUp">
              <CheckoutBtnBottom />

            </MHidden>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseFilterBtn}
              onClick={handleCloseFilterBtn}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

              {
                SORT_ITEMS_OPTIONS.map((item) => {
                  return <MenuItem key={item.value} value={item.label} onClick={(event) => handleClickFilterBtn(event, item)}>{item.label}</MenuItem>
                })
              }
            </Menu>
          </Container> :
          <Box>
            <Page404 />
          </Box>
        }
      </Page>
    </>
  );
}