import axios from "axios";
// constants
import { API_URL } from "../../constants/url.constant";
import { postMethod } from "../../utils/api";
import { getLocalData, getSessionData } from "../../utils/helper";

export const PayOrderByWalletAction = async ({ total_amount, items }) => {
  const user = JSON.parse(getLocalData("user"))
  const name = user ? user.firstName : ""
  const email = user ? user.email : ""
  const contact = user ? user.contact : ""
  const customer_id = user ? user.customer_id : ""

  const finalRes = await postMethod(API_URL.ORDER_BY_WALLET, { amount: total_amount, items: items, user: user }, false, false)
  const id = finalRes?.data?.data
  if (finalRes?.status === 200) {
    window.location.assign('/order/order-id/' + id)
  }
  else {
    //order failed action
  }
};
