export function decodeMachineMsg(code) {
  if (!code || !code?.slice(5, 13)) {
    return null
  }
  let status = code?.slice(3, 5);
  const pro_code = code?.slice(5, 13);
  let message = ""
  switch (status) {
    case 'OK':
      message = "Your Order is accepted to process further";
      break;
    case 'FL':
      message = "Order is not accepted, may be Machine is not connected/ready or order buffer is full";
      break;
    case 'st':
      message = "Order forwarded to machine for making";
      break;
    case 'un':
      message = "Status of machine is unknown, order will be tried again with in 10 sec";
      break;
    case 'ut':
      message = "Machine is not in position to make order so being terminated";
      break;
    case 'pr':
      message = "Machine making the order and order number is being displayed on Machine";
      break;
    case 'to':
      message = "Machine is not responding to order hence time-out";
      break;
    case 'tt':
      message = "Order can not be made hence terminated";
      break;
    case 'kk':
      message = "Order is successfully completed";
      break;
    case 'fl':
      message = "Order couldn’t be delivered";
      break;
    case 'rd':
      message = "Order is ready, Please pick it up and Enjoy";
      break;
    default:
      message = 'Something went wrong'
  }

  let response = { pro_code, status, message }

  return response;
}
