import { useRef, useState } from "react";

// material
import { alpha } from "@mui/material/styles";
import { Button, Box, Avatar, IconButton } from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//
// import account from "../../mock-data/account";

import { useNavigate } from 'react-router-dom';
import { getUser } from "../../utils/getUser";
import { Typography } from "@mui/material";
import { Divider } from "@mui/material";
import { checkUser } from "../../utils/checkUser";

// ----------------------------------------------------------------------

export default function UserAccountPopover() {
    const user = JSON.parse(getUser())
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const onLogOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
    };

    const logInOnClick = () => {
        navigate('/login');
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        },
                    }),
                }}
            >
                <Avatar alt="photoURL" />
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {user?.firstName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                {/* {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))} */}

                <Box sx={{ p: 2, pt: 1.5 }}>
                    {
                        checkUser() ? <Button onClick={onLogOut} fullWidth color="inherit" variant="outlined">
                            Logout
                        </Button> :
                            <Button onClick={logInOnClick} fullWidth color="inherit" variant="outlined">
                                Log In
                            </Button>
                    }

                </Box>
            </MenuPopover>
        </>
    );
}
