import { Icon } from "@iconify/react";
import home from "@iconify/icons-eva/home-fill";
import info from "@iconify/icons-eva/info-outline";
import baselineFastfood from '@iconify/icons-ic/baseline-fastfood';
import creditCard from '@iconify/icons-eva/credit-card-fill';
import { ROUTES_URL } from "../../constants/url.constant";

// import { ROUTES_URL } from "../../constants/url.constant";
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const Home = ROUTES_URL.HOME
console.log(Home)
const sidebarConfig = [
  {
    title: "Home",
    path: Home,
    icon: getIcon(home),
  },
  {
    title: "Orders",
    path: "/my-orders",
    icon: getIcon(baselineFastfood),
  },
  {
    title: "Wallet",
    path: "/wallet",
    icon: getIcon(creditCard),
  },
  // {s
  //   title: "About Us",
  //   path: "/about-us",
  //   icon: getIcon(home),
  // },
  // {
  //   title: "Contact Us",
  //   path: "/contact-us",
  //   icon: getIcon(home),
  // },
  // {
  //   title: "category",
  //   path: "/home/category",
  //   icon: getIcon(briefcaseFill),
  // },

];

export default sidebarConfig;
