import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/url.constant";
import toastr from "toastr";
import { postMethod } from "../../utils/api";
import { ROUTES_URL } from "../../constants/url.constant";


const INIT_STATE = {
  email: "",
  password: "",
  remember: true,
  loginStatus: false
};

const userSlice = createSlice({
  name: "user",
  initialState: INIT_STATE,
  reducers: {
    actionUser: (state, action) => {
      state.user = action.payload;
      return state;
    },
  },
});



export const loginUserAsyncThunk = createAsyncThunk(
  "users/login",
  async ({ email, password, remember }, thunkAPI) => {
    try {
      const response = await postMethod(API_URL.USER_LOGIN, { email, password, remember }, false, false)

      let data = response?.data
      if (response?.status === 200) {
        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('user', JSON.stringify(data.user));
        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      console.log("Error", e.response.data)
      thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const registerUserAsyncThunk = createAsyncThunk(
  "users/register",
  async ({ firstName, lastName, email, contact, password, discount }, thunkAPI) => {

    try {

      const response = await postMethod(API_URL.USER_REGISTER, { firstName, lastName, email, contact, password, discount }, false, false)

      let data = await response?.data
      if (response?.status === 200) {

        localStorage.setItem('token', data.accessToken);
        localStorage.setItem('user', JSON.stringify(data.user));

        return data
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (e) {
      console.log("Error", e.response.data)
      thunkAPI.rejectWithValue(e.response.data)
    }
  }
)

export const signupGoogle = (accessToken, navigate) => async (dispatch) => {
  try {
    // signup user
    const response = await postMethod(API_URL.USER_GOOGLE_SIGNUP, { googleAccessToken: accessToken }, false, false)
    let data = await response?.data

    if (response?.status === 200) {

      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('user', JSON.stringify(data.user));
      navigate(ROUTES_URL?.HOME, { replace: true });

    } else {
      return response?.status
    }
  } catch (err) {
    console.log(err)
  }
}

export const signInGoogle = (accessToken, navigate) => async (dispatch) => {
  try {
    // signup user
    const response = await postMethod(API_URL.USER_GOOGLE_SIGN_IN, { googleAccessToken: accessToken }, false, false)
    let data = await response?.data

    if (response?.status === 200) {

      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('user', JSON.stringify(data.user));
      navigate(ROUTES_URL?.HOME, { replace: true });

    } else {
      return response?.status
    }
  } catch (err) {
    console.log(err)
  }
}

export const { actionUser } = userSlice.actions;
export const userSelector = state => state.user

export default userSlice.reducer;
