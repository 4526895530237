import React, { useEffect, useState } from "react";
// import Link from "next/link";
import { Link } from "react-router-dom";
import OrderReceivedWrapper, {
  OrderReceivedContainer,
  OrderInfo,
  OrderDetails,
  TotalAmount,
  BlockTitle,
  Text,
  InfoBlockWrapper,
  InfoBlock,
  ListItem,
  ListTitle,
  ListDes,
} from "./order-received.style";
import { FormattedMessage } from "react-intl";
import { useCart } from "../../../contexts/cart/use-cart";
import Page from "../../../components/Page";
import { Outlet } from "react-router-dom";
import { useParams } from 'react-router-dom'
import { getMethod } from "../../../utils/api";
import { API_URL } from "../../../constants/url.constant";
import { API_STATUS_CODE } from "../../../constants/content.constant";
import { ROUTES_URL } from "../../../constants/url.constant";

const OrderReceived = (props) => {
  const { items, calculatePrice, calculateSubTotalPrice, clearCart } = useCart();
  const { id } = useParams()
  const [order, setOrder] = useState(null)

  const fetchInvoice = async () => {
    try {
      const response = await getMethod(API_URL.GET_ORDER_BY_ID + '/' + id, false, true);
      console.log("error", response)
      if (response?.status === API_STATUS_CODE.SUCCESS) {
        setOrder(response?.data?.data)
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    fetchInvoice()

  }, []);
  React.useEffect(() => {
    return () => {
      clearCart();
    };

  }, []);

  return (
    <>
      <Outlet />
      <Page title="Clover Carte | Order Invoice">
        <OrderReceivedWrapper>
          <OrderReceivedContainer>
            <Link to={ROUTES_URL.HOME}>
              <p className="home-btn">
                <FormattedMessage id="backHomeBtn" defaultMessage="Back to Home" />
              </p>
            </Link>

            {order ? (
              <>
                <OrderInfo>
                  <BlockTitle>
                    <FormattedMessage
                      id="orderReceivedText"
                      defaultMessage="Order Received"
                    />
                  </BlockTitle>

                  <Text>
                    <FormattedMessage
                      id="orderReceivedSuccess"
                      defaultMessage="Thank you. Your order has been received."
                    />
                    <br></br>
                    {/* <FormattedMessage
                      id="myOrderMessage"
                      defaultMessage="Track Your order here."
                    />
                    <Link to="/my-orders">
                      <a >
                        <FormattedMessage id="myOrderBtn" defaultMessage=" My Order" />
                      </a>
                    </Link> */}
                  </Text>

                  <InfoBlockWrapper>
                    <InfoBlock>
                      <Text bold className="title">
                        <FormattedMessage
                          id="orderNumberText"
                          defaultMessage="Order Number"
                        />
                      </Text>
                      <Text>{order.orderId}</Text>
                    </InfoBlock>

                    <InfoBlock>
                      <Text bold className="title">
                        <FormattedMessage
                          id="orderDateText"
                          defaultMessage="Date"
                        />
                      </Text>
                      <Text> {(new Date(order.updatedAt)).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}</Text>
                    </InfoBlock>

                    <InfoBlock>
                      <Text bold className="title">
                        <FormattedMessage id="totalText" defaultMessage="Total" />
                      </Text>
                      <Text>Rs. {calculatePrice()}</Text>
                    </InfoBlock>

                    <InfoBlock>
                      <Text bold className="title">
                        <FormattedMessage
                          id="paymenMethodText"
                          defaultMessage="Payment Method"
                        />
                      </Text>
                      <Text>Online</Text>
                    </InfoBlock>
                  </InfoBlockWrapper>
                </OrderInfo>

                <OrderDetails>
                  <BlockTitle>
                    <FormattedMessage
                      id="orderDetailsText"
                      defaultMessage="Order Details"
                    />
                  </BlockTitle>

                  <ListItem>
                    <ListTitle>
                      <Text bold>
                        <FormattedMessage
                          id="totalItemText"
                          defaultMessage="Total Item"
                        />
                      </Text>
                    </ListTitle>
                    <ListDes>
                      <Text>{order?.items?.length} Items</Text>
                    </ListDes>
                  </ListItem>

                  {/* <ListItem>
            <ListTitle>
              <Text bold>
                <FormattedMessage
                  id="orderTimeText"
                  defaultMessage="Order Time"
                />
              </Text>
            </ListTitle>
            <ListDes>
              <Text>1.00pm 10/12/19</Text>
            </ListDes>
          </ListItem> */}

                  {/* <ListItem>
            <ListTitle>
              <Text bold>
                <FormattedMessage
                  id="deliveryTimeText"
                  defaultMessage="Delivery Time"
                />
              </Text>
            </ListTitle>
            <ListDes>
              <Text>90 Minute Express Delivery</Text>
            </ListDes>
          </ListItem> */}

                  {/* <ListItem>
            <ListTitle>
              <Text bold>
                <FormattedMessage
                  id="deliveryLocationText"
                  defaultMessage="Delivery Location"
                />
              </Text>
            </ListTitle>
            <ListDes>
              <Text>
                1st Floor, House 149, Road-22, Mohakhali DOHS, Dhaka - North
              </Text>
            </ListDes>
          </ListItem>*/}
                </OrderDetails>

                <TotalAmount>
                  <BlockTitle>
                    <FormattedMessage
                      id="totalAmountText"
                      defaultMessage="Total Amount"
                    />
                  </BlockTitle>

                  <ListItem>
                    <ListTitle>
                      <Text bold>
                        <FormattedMessage
                          id="subTotal"
                          defaultMessage="Sub total"
                        />
                      </Text>
                    </ListTitle>
                    <ListDes>
                      <Text>Rs. {calculateSubTotalPrice()}</Text>
                    </ListDes>
                  </ListItem>

                  <ListItem>
                    <ListTitle>
                      <Text bold>
                        <FormattedMessage
                          id="paymenMethodText"
                          defaultMessage="Payment Method"
                        />
                      </Text>
                    </ListTitle>
                    <ListDes>
                      <Text>Online</Text>
                    </ListDes>
                  </ListItem>

                  {/* <ListItem>
            <ListTitle>
              <Text bold>
                <FormattedMessage
                  id="paymentMethodName"
                  defaultMessage="Delivery Charge"
                />
              </Text>
            </ListTitle>
            <ListDes>
              <Text>10</Text>
            </ListDes>
          </ListItem> */}

                  <ListItem>
                    <ListTitle>
                      <Text bold>
                        <FormattedMessage id="totalText" defaultMessage="Total" />
                      </Text>
                    </ListTitle>
                    <ListDes>
                      <Text>Rs. {calculatePrice()}</Text>
                    </ListDes>
                  </ListItem>
                </TotalAmount>
              </>
            ) : (
              <OrderInfo>
                <BlockTitle>You don't have any orders.</BlockTitle>
              </OrderInfo>
            )}
          </OrderReceivedContainer>
        </OrderReceivedWrapper>
      </Page>
    </>

  );
};

export default OrderReceived;
